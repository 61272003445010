html {
  box-sizing: border-box;
  font-weight: 500;
  font-size: 10px;
  height: 100vh;
}
*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
html {
  font-size: 62.5%;
  overflow-y: scroll;
}
body {
  background-color: #154355;
  background: fixed -webkit-linear-gradient( #154355 25%, #d27f2c 50%, #154355 75%);
  background: fixed linear-gradient( #154355 25%, #d27f2c 50%, #154355 75%);
  margin: 0px;
  min-height: 100%;
  letter-spacing: 0px;
  color: #ddd;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1, h2, h3, h4, h5, h6 {
  margin: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
  color: #d27f2c;
}
a:hover {
  color: #fff;
}
a:visited {
  color: #abd;
  text-decoration: underline;
}
.App {
  font-size: 1.6rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAA30lEQVQokZWTQQ7BQBiFv4orWFuwYaWuwAmIQ2BFggVLFljTxCE4gDtgZc9FKn8y04zp36ovmUU7r6//9L0Glc4gJs0MeABXZU/oAiGw9zdKivgFHDWxww44GO1PwxXQB1o5hm2gZ7RflL3rO3AGnjlmlg3QACbmBeqEc2AMVAsYimYELPwJA094KmBmGQJ110MmjJ0l6UV/GEbmmcTDP7JN713AzLZh6970DcOs9BSWpg1JIIKfsrAGmsA0pzo34KK1QeuhpCcfW/6WLKQNokm1QZtQkDrUlAa4pNsAfADZYyYQBPbx1AAAAABJRU5ErkJggg==);
  min-height: 100vh;
  display: grid;
  grid-template-areas:
    '. header header header .'
    '. hero hero hero .'
    '. content content content .'
    '. footer footer footer .';
  grid-template-columns: 1fr 3fr 5fr 3fr 1fr;
  grid-template-rows: auto auto 1fr auto;
}
.hidden {
  display: none;
}
.header__navbar a {
  text-decoration: none;
  color: #eee;
}
.head__container {
  grid-area: header;
  background: #1d5d76;
  display: grid;
  grid-template-areas:
  '. logo . navbar social .';
  padding: 1rem 2rem 1rem 2rem;
  border-bottom: 4px solid #d27f2c;
  box-shadow: .1rem .1rem 1rem #222;
  border-radius: 0 0 2rem 2rem;
  z-index: 1;
}
.logo {
  display: grid;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: start;
          justify-content: start;
  grid-area: logo;
}
.logo svg {
  width: 5rem;
  height: 4rem;
}
.logo--opacity {
  opacity: .7;
}
.header__navbar {
  display: grid;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: center;
          justify-content: center;
  grid-area: navbar;
}
.header__navbar li {
  display: inline;
  padding: 0;
  margin: 0 2rem 0 2rem;
}
.header__navbar li:not(:last-child)::after {
  content: '|';
  left: 2.1rem;
  top: -.2rem;
  position: relative;
}
.social li {
  text-align: center;
}
.social__links {
  display: grid;
  grid-area: social;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: end;
          justify-content: end;
}
.social a {
  padding: .3rem;
}
.social svg {
  color: #ddd;
  width: 2rem;
  height: 2rem;
}
.social path {
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
}
.social svg:hover path {
  -webkit-transition: fill .3s;
  transition: fill .3s;
  fill: #d27f2c;
}
.header__navbar li.active {
  border-style: solid;
  border-width: .2rem 0 .2rem 0;
  border-color: hsl(30, 65%, 60%);
  border-radius: .5rem;
}
li {
  list-style: none;
}
.header__navbar li {
  text-align: center;
  color: #ddd;
  text-decoration: none;
  border-radius: .5rem;
  text-transform: uppercase;
}
.header__navbar li:hover a {
  -webkit-transition: color .3s;
  transition: color .3s;
  color: hsl(30, 65%, 60%);
}
.important {
  color: hsl(30, 65%, 60%);
  text-shadow:
   -1px -1px 0 #fff,
   1px -1px 0 #fff,
   -1px 1px 0 #fff,
   1px 1px 0 #fff;
}
.hero {
  grid-area: hero;
  background-image: -webkit-linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(/static/media/code_hero.553a034a.jpg);
  background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(/static/media/code_hero.553a034a.jpg);
  background-size: 100vw;
  -webkit-transform: translateY(-1.5rem);
          transform: translateY(-1.5rem);
  text-transform: uppercase;
  overflow: hidden;
}
.hero__intro {
  grid-area: hero_intro;
  text-transform: none;
  align-self: end;
  opacity: .7;
}
.hero__and {
  grid-area: hero_and;
  align-self: end;
  text-transform: none;
  opacity: .7;
}
.hero__firstname {
  grid-area: hero_first;
  align-self: end;
  font-size: 8.43rem;
  font-weight: 850;
}
.hero__lastname {
  -webkit-transform: translate(0.3rem, -2.6rem);
          transform: translate(0.3rem, -2.6rem);
  grid-area: hero_last;
  align-self: start;
  opacity: 0.7;
  font-size: 4.44rem;
  font-weight: 850;
}
.hero__webdev {
  -webkit-transform: translateY(-0.5rem);
          transform: translateY(-0.5rem);
  grid-area: hero_front;
  font-size: 4rem;
  text-transform: lowercase;
  overflow: hidden;
  white-space: nowrap;
}
.hero__grid {
  display: grid;
  grid-template-areas:
  'hero_intro .'
  'hero_first hero_and'
  'hero_last hero_front';
  grid-template-columns: auto 1fr;
  grid-template-rows: 1rem 7.6rem auto;
  margin-top: 6rem;
  margin-left: 6rem;
}
.content {
  background: #154355;
  grid-area: content;
  display: -webkit-flex;
  display: flex;
  margin-top: -2rem;
  margin-bottom: -2rem;
  z-index: 0;
  padding: 1rem;
  padding-bottom: 4rem;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-content: flex-start;
          align-content: flex-start;
  box-shadow: .1rem .1rem 1rem #222;
  overflow: hidden;
}
.content--single {
  padding-top: 3rem;
}
section p {
  letter-spacing: normal;
  font-weight: normal;
  line-height: 2.5rem;
  padding-top: 2rem;
}
section p img {
  box-sizing: content-box;
}
.image__fam {
  width: 25rem;
  height: 25rem;
  float: left;
  padding: 0.5rem 1rem 0 0;
  border-radius: 5rem 0 0 0;
}
.svg--large {
  height: 11.5rem;
  float: left;
  padding: .5rem 1rem 0 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  opacity: 0.3;
  z-index: 0;
}
#about {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 1rem;
  width: 73rem;
}
#about p {
  line-height: 2rem;
  font-size: 1.6rem;
  padding: 1rem 0;
}
#about h1 {
  color: #d27f2c;
  font-size: 4rem;
  font-weight: 100;
  margin-left: 4rem;
}
#projects {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin: 1rem;
  width: 73rem;
}
.project {
  display: grid;
  grid-template-areas:
  'title title'
  'image list';
  width: 100%;
  -webkit-justify-content: start;
          justify-content: start;
  margin: 0;
}
.project > h2 {
  grid-area: title;
  color: #d27f2c;
  text-align: center;
  margin: 0 0 0 2rem;
  padding-top: 1rem;
  font-weight: 100;
  text-shadow: .2rem .2rem 0 #333;
}
.project h2 a {
  text-decoration: none;
}
.project > img {
  grid-area: image;
  align-self: start;
  margin-top: 2.5rem;
}
.project > a {
  grid-area: image;
  align-self: start;
  margin-top: 2.5rem;
}
#project .project > ul {
  grid-area: list;
  text-align: left;
}
#projects h1 {
  color: #d27f2c;
  font-size: 4rem;
  font-weight: 100;
  margin-left: 4rem;
}
#projects li {
  font-weight: 100;
  margin: 2rem;
}
#projects strong {
  font-weight: 500;
}
#projects div:nth-child(2) {
  margin: 1rem 0 0 0;
}
#projects div {
  /* margin: 3rem 0 0 0; */
  padding: 0 0 2rem;
  line-height: 2rem;
}
#projects p:nth-child(2) {
  margin-top: 1rem;
}
#projects img {
  width: 20rem;
  border: .1rem solid #d27f2c;
  border-radius: 1rem;
}
#contact {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 1rem;
  width: 73rem;
}
.contact__form form {
  padding: 2rem;
  width: 80%;
}
#contact > h1 {
  justify-self: left;
  color: #d27f2c;
  font-size: 4rem;
  font-weight: 100;
  margin-left: 4rem;
}
#contact label {
  font-size: 2rem;
  text-align: start;
  vertical-align: middle;
  padding: 1rem;
}
#contact input {
  color: #ddd;
  background: #154355;
  border: 1px solid #d27f2c;
  border-radius: 1rem;
  padding: 1rem;
  line-height: 2rem;
}
#contact input:focus {
  outline: none;
  background: #1d5d76;
  border: 1px solid #abd;
  border-radius: 1rem;
}
#contact textarea {
  color: #ddd;
  background: #154355;
  border: 1px solid #d27f2c;
  border-radius: 1rem;
  padding: 1rem;
  line-height: 2rem;
}
#contact textarea:focus {
  outline: none;
  background: #1d5d76;
  border: 1px solid #abd;
  border-radius: 1rem;
}
#contact form button {
  -webkit-align-self: flex-end;
          align-self: flex-end;
  border-style:none;
  background-color: #154355;
  border: 1px solid #d27f2c;
  color: #ddd;
  font-size: 2rem;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 1rem;
}
#closeBtn {
  position: absolute;
  padding: .2rem;
  right: .5rem;
  top: .5rem;
  line-height: 1rem;
  cursor: pointer;
  color: #fff;
  font-size: 3rem;
  font-weight: 100;
}
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: -webkit-linear-gradient(rgba(0, 0, 0,0.9), rgba(0,0,0,0.4), rgba(0,0,0,0.9));
  background: linear-gradient(rgba(0, 0, 0,0.9), rgba(0,0,0,0.4), rgba(0,0,0,0.9));
}
.thank-you h1 {
  font-size: 4rem;
  font-weight: 100;
  text-shadow: 2px 2px 2px rgba(0,0,0,0.3);
  color: #d27f2c;
  margin: 0;
}
.thank-you h4 {
  margin: 1rem;
  font-weight: 100;
}
.thank-you {
  text-align: center;
  color: #fff;
  -webkit-transition-timing-function: ease-in-out;
          transition-timing-function: ease-in-out;
  opacity: 0;
  padding: 2rem;
  border-radius: 2rem;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -600%);
          transform: translate(-50%, -600%);
  position: absolute;
  border: 3px solid hsl(30, 65%, 50%);
  background: #1d5d76;
  z-index: 2;
}
.card {
  width: 25rem;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  margin: 1rem;
  display: grid;
  grid-template-areas:
  "card__header"
  "card__paragraph";
  color: #ddd;
  overflow: hidden;
}
.card--click {
  cursor: pointer;
}
.card__title {
  text-align: center;
  grid-area: card__header;
  z-index: 1;
}
.card__title h1 {
  text-shadow: .2rem .2rem .3rem #222;
  font-size: 3.2rem;
  margin-top: 0.5rem;
}
.card__body {
  font-size: 1.7rem;
  line-height: 2.7rem;
  z-index: 1;
  text-align: center;
  grid-area: card__paragraph;
}
.card__body p {
  letter-spacing: normal;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-top: 0;
  padding-top: 0;
}
.card--hover:hover {
  -webkit-transform: translate(-0.2rem, -0.2rem);
          transform: translate(-0.2rem, -0.2rem);
  box-shadow: .5rem .5rem 2rem #222;
}
.card--hover:hover .svg--large {
  opacity: 1;
}
.card__list {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.card__list li:nth-child(odd) {
  padding-right: 1rem;
  text-align: right;
  -webkit-flex-basis: 10rem;
          flex-basis: 10rem;
}
.card__list li:nth-child(even) {
  padding-left: 1rem;
  text-align: left;
  -webkit-flex-basis: 10rem;
          flex-basis: 10rem;
}
.card__list li:last-child {
  padding: 0.5rem;
  text-align: center;
  -webkit-flex-basis: 10rem;
          flex-basis: 10rem;
}
.card--linked {
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.5);
  border-radius: 1rem;
  -webkit-transition: box-shadow 0.3s, -webkit-transform 0.3s;
  transition: box-shadow 0.3s, -webkit-transform 0.3s;
  transition: box-shadow 0.3s, transform 0.3s;
  transition: box-shadow 0.3s, transform 0.3s, -webkit-transform 0.3s;
  padding: 1rem;
}
.card--linked:hover {
  -webkit-transform: translate(2px, 2px);
          transform: translate(2px, 2px);
  box-shadow: 0 0 3px rgba(0,0,0,0.5);
}
.image__html {
  width: 6rem;
}
.image__css {
  width: 6rem;
}
.image__javascript {
  width: 6rem;
}
.form__contact {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.footer {
  padding: 2rem;
  grid-area: footer;
  height: 100%;
  text-align: center;
  background: #1d5d76;
  border-radius: 2rem 2rem 0 0;
  box-shadow: .1rem .5rem 1rem #222;
  z-index: 1;
}

@media screen and (min-width: 1400px) {
  .App {
    grid-template-areas:
    ". . header header header . ."
    ". . hero hero hero . ."
    ". . content content content . ."
    ". . footer footer footer . .";
    grid-template-columns: 1fr 1fr 2fr 2fr 2fr 1fr 1fr;
  }
  .hero {
    background-size: 100%;
  }
}
@media screen and (max-width: 410px) {
  .header__navbar li {
    margin: 0 0.5rem 0 0.5rem;
    font-size: 1.5rem;
  }
  .header__navbar li:not(:last-child)::after {
    content: '';
  }
  #about .image__fam {
    padding: 1rem;
    margin: 0 4rem;
  }
}
@media screen and (max-width: 500px) {
  .image__fam {
    border-radius: 5rem 5rem 0 0;
    margin: 0 9rem 1rem;
  }
}
@media screen and (max-width: 630px) {
  #about {
    width: 100%;
  }
  #about p {
    width: 100%;
  }
  #about h1 {
    margin: auto;
    padding: 0;
  }
  .head__container {
  grid-template-areas:
  'logo'
  'navbar'
  'social';
  grid-template-columns: auto;
  grid-template-rows: auto auto auto;
  }
  .App {
    grid-template-columns: 0 auto auto auto 0;
    margin: 0;
  }
  .content {
    grid-template-columns: auto;
  }
  .logo {
    -webkit-justify-content: center;
            justify-content: center;
  }
  .header__navbar {
    padding-top: 2rem;
    padding-bottom: 1rem;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .header__navbar li {
    margin: 0 0.5rem 0 0.5rem;
  }
  .header__navbar li:not(:last-child)::after {
    left: 0.5rem;
  }
  .social__links {
    padding-top: 1rem;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .social path {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  .social svg {
    width: 3rem;
    height: 3rem;
  }
  #projects h1 {
    margin: auto;
  }
  .project {
    grid-template-areas:
    'title'
    'image'
    'list';
    text-align: left;
    justify-items: center;
  }
  .project h2 {
    margin: 0;
  }
  #contact h1 {
    margin: auto;
  }
}
@media screen and (max-width: 980px) {
  .hero__grid {
    text-align: center;
    grid-template-columns: 1fr;
    grid-template-areas:
  'hero_intro'
  'hero_first'
  'hero_last'
  'hero_and'
  'hero_front';
  margin: 4rem 0 0 0;
  }
  .hero__intro {
    padding-bottom: 1rem;
  }
  .hero__lastname {
    -webkit-transform: translateY(-2.4rem);
            transform: translateY(-2.4rem);
  }
  .hero__and {
    -webkit-transform: translateY(-2.4rem);
            transform: translateY(-2.4rem);
  }
  .hero__webdev {
    -webkit-transform: translateY(-2.4rem);
            transform: translateY(-2.4rem);
  }
}

/*! sanitize.css v8.0.0 | CC0 License | github.com/csstools/sanitize.css */

/* Document
 * ========================================================================== */

/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add border box sizing in all browsers (opinionated).
 */

*,
::before,
::after {
  background-repeat: no-repeat; /* 1 */
  box-sizing: border-box; /* 2 */
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritance in all browsers (opinionated).
 */

::before,
::after {
  text-decoration: inherit; /* 1 */
  vertical-align: inherit; /* 2 */
}

/**
 * 1. Use the default cursor in all browsers (opinionated).
 * 2. Use the default user interface font in all browsers (opinionated).
 * 3. Correct the line height in all browsers.
 * 4. Use a 4-space tab width in all browsers (opinionated).
 * 5. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 * 6. Breaks words to prevent overflow in all browsers (opinionated).
 */

html {
  cursor: default; /* 1 */
  font-family:
    system-ui,
    /* macOS 10.11-10.12 */ -apple-system,
    /* Windows 6+ */ Segoe UI,
    /* Android 4+ */ Roboto,
    /* Ubuntu 10.10+ */ Ubuntu,
    /* Gnome 3+ */ Cantarell,
    /* KDE Plasma 5+ */ Noto Sans,
    /* fallback */ sans-serif,
    /* macOS emoji */ "Apple Color Emoji",
    /* Windows emoji */ "Segoe UI Emoji",
    /* Windows emoji */ "Segoe UI Symbol",
    /* Linux emoji */ "Noto Color Emoji"; /* 2 */

  line-height: 1.15; /* 3 */
  -moz-tab-size: 4; /* 4 */
  tab-size: 4; /* 4 */
  -ms-text-size-adjust: 100%; /* 5 */
  -webkit-text-size-adjust: 100%; /* 5 */
  /* word-break: break-word; 6 */
}

/* Sections
 * ========================================================================== */

/**
 * Remove the margin in all browsers (opinionated).
 */

body {
  margin: 0;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */

/* h1 {
  font-size: 2em;
  margin: 0.67em 0;
} */

/* Grouping content
 * ========================================================================== */

/**
 * 1. Add the correct sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */

hr {
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * Add the correct display in IE.
 */

main {
  display: block;
}

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */

nav ol,
nav ul {
  list-style: none;
}

/**
 * 1. Use the default monospace user interface font
 *    in all browsers (opinionated).
 * 2. Correct the odd `em` font sizing in all browsers.
 */

pre {
  font-family:
    /* macOS 10.10+ */ Menlo,
    /* Windows 6+ */ Consolas,
    /* Android 4+ */ Roboto Mono,
    /* Ubuntu 10.10+ */ Ubuntu Monospace,
    /* KDE Plasma 5+ */ Noto Mono,
    /* KDE Plasma 4+ */ Oxygen Mono,
    /* Linux/OpenOffice fallback */ Liberation Mono,
    /* fallback */ monospace; /* 1 */

  font-size: 1em; /* 2 */
}

/* Text-level semantics
 * ========================================================================== */

/**
 * Remove the gray background on active links in IE 10.
 */

a {
  background-color: transparent;
}

/**
 * Add the correct text decoration in Edge, IE, Opera, and Safari.
 */

abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
  font-weight: bolder;
}

/**
 * 1. Use the default monospace user interface font
 *    in all browsers (opinionated).
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
  font-family:
    /* macOS 10.10+ */ Menlo,
    /* Windows 6+ */ Consolas,
    /* Android 4+ */ Roboto Mono,
    /* Ubuntu 10.10+ */ Ubuntu Monospace,
    /* KDE Plasma 5+ */ Noto Mono,
    /* KDE Plasma 4+ */ Oxygen Mono,
    /* Linux/OpenOffice fallback */ Liberation Mono,
    /* fallback */ monospace; /* 1 */

  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}

/*
 * Remove the text shadow on text selections in Firefox 61- (opinionated).
 * 1. Restore the coloring undone by defining the text shadow
 *    in all browsers (opinionated).
 */

::-moz-selection {
  background-color: #b3d4fc; /* 1 */
  color: #000; /* 1 */
  text-shadow: none;
}

::selection {
  background-color: #b3d4fc; /* 1 */
  color: #000; /* 1 */
  text-shadow: none;
}

/* Embedded content
 * ========================================================================== */

/*
 * Change the alignment on media elements in all browers (opinionated).
 */

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

/**
 * Add the correct display in IE 9-.
 */

audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */

audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on images inside links in IE 10-.
 */

img {
  border-style: none;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */

svg:not([fill]) {
  fill: currentColor;
}

/**
 * Hide the overflow in IE.
 */

svg:not(:root) {
  overflow: hidden;
}

/* Tabular data
 * ========================================================================== */

/**
 * Collapse border spacing in all browsers (opinionated).
 */

table {
  border-collapse: collapse;
}

/* Forms
 * ========================================================================== */

/**
 * Inherit styling in all browsers (opinionated).
 */

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

/**
 * Remove the margin in Safari.
 */

button,
input,
select {
  margin: 0;
}

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge, Firefox, and IE.
 */

button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
 * Correct the padding in Firefox.
 */

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * Show the overflow in Edge and IE.
 */

input {
  overflow: visible;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */

legend {
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}

/**
 * 1. Add the correct display in Edge and IE.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */

progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
 * Remove the inheritance of text transform in Firefox.
 */

select {
  text-transform: none;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 * 3. Change the resize direction on textareas in all browsers (opinionated).
 */

textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
  resize: vertical; /* 3 */
}

/**
 * Remove the padding in IE 10-.
 */

[type="checkbox"],
[type="radio"] {
  padding: 0;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/* Interactive
 * ========================================================================== */

/*
 * Add the correct display in Edge and IE.
 */

details {
  display: block;
}

/*
 * Add the correct styles in Edge, IE, and Safari.
 */

dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

dialog:not([open]) {
  display: none;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/* Scripting
 * ========================================================================== */

/**
 * Add the correct display in IE 9-.
 */

canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */

template {
  display: none;
}

/* User interaction
 * ========================================================================== */

/*
 * 1. Remove the tapping delay in IE 10.
 * 2. Remove the tapping delay on clickable elements
      in all browsers (opinionated).
 */

a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] { /* 1 */
  touch-action: manipulation; /* 2 */
}

/**
 * Add the correct display in IE 10-.
 */

[hidden] {
  display: none;
}

/* Accessibility
 * ========================================================================== */

/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */

[aria-busy="true"] {
  cursor: progress;
}

/*
 * Change the cursor on control elements in all browsers (opinionated).
 */

[aria-controls] {
  cursor: pointer;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers (opinionated).
 */

[aria-disabled="true"],
[disabled] {
  cursor: not-allowed;
}

/*
 * Change the display on visually hidden accessible elements
 * in all browsers (opinionated).
 */

[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

