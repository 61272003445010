html {
  box-sizing: border-box;
  font-weight: 500;
  font-size: 10px;
  height: 100vh;
}
*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
html {
  font-size: 62.5%;
  overflow-y: scroll;
}
body {
  background-color: #154355;
  background: fixed linear-gradient( #154355 25%, #d27f2c 50%, #154355 75%);
  margin: 0px;
  min-height: 100%;
  letter-spacing: 0px;
  color: #ddd;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1, h2, h3, h4, h5, h6 {
  margin: 0;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
  color: #d27f2c;
}
a:hover {
  color: #fff;
}
a:visited {
  color: #abd;
  text-decoration: underline;
}
.App {
  font-size: 1.6rem;
  background: url(./images/hexa_dark.png);
  min-height: 100vh;
  display: grid;
  grid-template-areas:
    '. header header header .'
    '. hero hero hero .'
    '. content content content .'
    '. footer footer footer .';
  grid-template-columns: 1fr 3fr 5fr 3fr 1fr;
  grid-template-rows: auto auto 1fr auto;
}
.hidden {
  display: none;
}
.header__navbar a {
  text-decoration: none;
  color: #eee;
}
.head__container {
  grid-area: header;
  background: #1d5d76;
  display: grid;
  grid-template-areas:
  '. logo . navbar social .';
  padding: 1rem 2rem 1rem 2rem;
  border-bottom: 4px solid #d27f2c;
  box-shadow: .1rem .1rem 1rem #222;
  border-radius: 0 0 2rem 2rem;
  z-index: 1;
}
.logo {
  display: grid;
  align-content: center;
  justify-content: start;
  grid-area: logo;
}
.logo svg {
  width: 5rem;
  height: 4rem;
}
.logo--opacity {
  opacity: .7;
}
.header__navbar {
  display: grid;
  align-content: center;
  justify-content: center;
  grid-area: navbar;
}
.header__navbar li {
  display: inline;
  padding: 0;
  margin: 0 2rem 0 2rem;
}
.header__navbar li:not(:last-child)::after {
  content: '|';
  left: 2.1rem;
  top: -.2rem;
  position: relative;
}
.social li {
  text-align: center;
}
.social__links {
  display: grid;
  grid-area: social;
  align-content: center;
  justify-content: end;
}
.social a {
  padding: .3rem;
}
.social svg {
  color: #ddd;
  width: 2rem;
  height: 2rem;
}
.social path {
  transform: scale(0.7);
}
.social svg:hover path {
  transition: fill .3s;
  fill: #d27f2c;
}
.header__navbar li.active {
  border-style: solid;
  border-width: .2rem 0 .2rem 0;
  border-color: hsl(30, 65%, 60%);
  border-radius: .5rem;
}
li {
  list-style: none;
}
.header__navbar li {
  text-align: center;
  color: #ddd;
  text-decoration: none;
  border-radius: .5rem;
  text-transform: uppercase;
}
.header__navbar li:hover a {
  transition: color .3s;
  color: hsl(30, 65%, 60%);
}
.important {
  color: hsl(30, 65%, 60%);
  text-shadow:
   -1px -1px 0 #fff,
   1px -1px 0 #fff,
   -1px 1px 0 #fff,
   1px 1px 0 #fff;
}
.hero {
  grid-area: hero;
  background-image: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url("./images/code_hero.jpg");
  background-size: 100vw;
  transform: translateY(-1.5rem);
  text-transform: uppercase;
  overflow: hidden;
}
.hero__intro {
  grid-area: hero_intro;
  text-transform: none;
  align-self: end;
  opacity: .7;
}
.hero__and {
  grid-area: hero_and;
  align-self: end;
  text-transform: none;
  opacity: .7;
}
.hero__firstname {
  grid-area: hero_first;
  align-self: end;
  font-size: 8.43rem;
  font-weight: 850;
}
.hero__lastname {
  transform: translate(0.3rem, -2.6rem);
  grid-area: hero_last;
  align-self: start;
  opacity: 0.7;
  font-size: 4.44rem;
  font-weight: 850;
}
.hero__webdev {
  transform: translateY(-0.5rem);
  grid-area: hero_front;
  font-size: 4rem;
  text-transform: lowercase;
  overflow: hidden;
  white-space: nowrap;
}
.hero__grid {
  display: grid;
  grid-template-areas:
  'hero_intro .'
  'hero_first hero_and'
  'hero_last hero_front';
  grid-template-columns: auto 1fr;
  grid-template-rows: 1rem 7.6rem auto;
  margin-top: 6rem;
  margin-left: 6rem;
}
.content {
  background: #154355;
  grid-area: content;
  display: flex;
  margin-top: -2rem;
  margin-bottom: -2rem;
  z-index: 0;
  padding: 1rem;
  padding-bottom: 4rem;
  flex-flow: row wrap;
  justify-content: space-around;
  align-content: flex-start;
  box-shadow: .1rem .1rem 1rem #222;
  overflow: hidden;
}
.content--single {
  padding-top: 3rem;
}
section p {
  letter-spacing: normal;
  font-weight: normal;
  line-height: 2.5rem;
  padding-top: 2rem;
}
section p img {
  box-sizing: content-box;
}
.image__fam {
  width: 25rem;
  height: 25rem;
  float: left;
  padding: 0.5rem 1rem 0 0;
  border-radius: 5rem 0 0 0;
}
.svg--large {
  height: 11.5rem;
  float: left;
  padding: .5rem 1rem 0 0;
  transition: opacity 0.3s;
  opacity: 0.3;
  z-index: 0;
}
#about {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem;
  width: 73rem;
}
#about p {
  line-height: 2rem;
  font-size: 1.6rem;
  padding: 1rem 0;
}
#about h1 {
  color: #d27f2c;
  font-size: 4rem;
  font-weight: 100;
  margin-left: 4rem;
}
#projects {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem;
  width: 73rem;
}
.project {
  display: grid;
  grid-template-areas:
  'title title'
  'image list';
  width: 100%;
  justify-content: start;
  margin: 0;
}
.project > h2 {
  grid-area: title;
  color: #d27f2c;
  text-align: center;
  margin: 0 0 0 2rem;
  padding-top: 1rem;
  font-weight: 100;
  text-shadow: .2rem .2rem 0 #333;
}
.project h2 a {
  text-decoration: none;
}
.project > img {
  grid-area: image;
  align-self: start;
  margin-top: 2.5rem;
}
.project > a {
  grid-area: image;
  align-self: start;
  margin-top: 2.5rem;
}
#project .project > ul {
  grid-area: list;
  text-align: left;
}
#projects h1 {
  color: #d27f2c;
  font-size: 4rem;
  font-weight: 100;
  margin-left: 4rem;
}
#projects li {
  font-weight: 100;
  margin: 2rem;
}
#projects strong {
  font-weight: 500;
}
#projects div:nth-child(2) {
  margin: 1rem 0 0 0;
}
#projects div {
  /* margin: 3rem 0 0 0; */
  padding: 0 0 2rem;
  line-height: 2rem;
}
#projects p:nth-child(2) {
  margin-top: 1rem;
}
#projects img {
  width: 20rem;
  border: .1rem solid #d27f2c;
  border-radius: 1rem;
}
#contact {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 1rem;
  width: 73rem;
}
.contact__form form {
  padding: 2rem;
  width: 80%;
}
#contact > h1 {
  justify-self: left;
  color: #d27f2c;
  font-size: 4rem;
  font-weight: 100;
  margin-left: 4rem;
}
#contact label {
  font-size: 2rem;
  text-align: start;
  vertical-align: middle;
  padding: 1rem;
}
#contact input {
  color: #ddd;
  background: #154355;
  border: 1px solid #d27f2c;
  border-radius: 1rem;
  padding: 1rem;
  line-height: 2rem;
}
#contact input:focus {
  outline: none;
  background: #1d5d76;
  border: 1px solid #abd;
  border-radius: 1rem;
}
#contact textarea {
  color: #ddd;
  background: #154355;
  border: 1px solid #d27f2c;
  border-radius: 1rem;
  padding: 1rem;
  line-height: 2rem;
}
#contact textarea:focus {
  outline: none;
  background: #1d5d76;
  border: 1px solid #abd;
  border-radius: 1rem;
}
#contact form button {
  align-self: flex-end;
  border-style:none;
  background-color: #154355;
  border: 1px solid #d27f2c;
  color: #ddd;
  font-size: 2rem;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 1rem;
}
#closeBtn {
  position: absolute;
  padding: .2rem;
  right: .5rem;
  top: .5rem;
  line-height: 1rem;
  cursor: pointer;
  color: #fff;
  font-size: 3rem;
  font-weight: 100;
}
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: linear-gradient(rgba(0, 0, 0,0.9), rgba(0,0,0,0.4), rgba(0,0,0,0.9));
}
.thank-you h1 {
  font-size: 4rem;
  font-weight: 100;
  text-shadow: 2px 2px 2px rgba(0,0,0,0.3);
  color: #d27f2c;
  margin: 0;
}
.thank-you h4 {
  margin: 1rem;
  font-weight: 100;
}
.thank-you {
  text-align: center;
  color: #fff;
  transition-timing-function: ease-in-out;
  opacity: 0;
  padding: 2rem;
  border-radius: 2rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -600%);
  position: absolute;
  border: 3px solid hsl(30, 65%, 50%);
  background: #1d5d76;
  z-index: 2;
}
.card {
  width: 25rem;
  flex-grow: 1;
  margin: 1rem;
  display: grid;
  grid-template-areas:
  "card__header"
  "card__paragraph";
  color: #ddd;
  overflow: hidden;
}
.card--click {
  cursor: pointer;
}
.card__title {
  text-align: center;
  grid-area: card__header;
  z-index: 1;
}
.card__title h1 {
  text-shadow: .2rem .2rem .3rem #222;
  font-size: 3.2rem;
  margin-top: 0.5rem;
}
.card__body {
  font-size: 1.7rem;
  line-height: 2.7rem;
  z-index: 1;
  text-align: center;
  grid-area: card__paragraph;
}
.card__body p {
  letter-spacing: normal;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-top: 0;
  padding-top: 0;
}
.card--hover:hover {
  transform: translate(-0.2rem, -0.2rem);
  box-shadow: .5rem .5rem 2rem #222;
}
.card--hover:hover .svg--large {
  opacity: 1;
}
.card__list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.card__list li:nth-child(odd) {
  padding-right: 1rem;
  text-align: right;
  flex-basis: 10rem;
}
.card__list li:nth-child(even) {
  padding-left: 1rem;
  text-align: left;
  flex-basis: 10rem;
}
.card__list li:last-child {
  padding: 0.5rem;
  text-align: center;
  flex-basis: 10rem;
}
.card--linked {
  cursor: pointer;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.5);
  border-radius: 1rem;
  transition: box-shadow 0.3s, transform 0.3s;
  padding: 1rem;
}
.card--linked:hover {
  transform: translate(2px, 2px);
  box-shadow: 0 0 3px rgba(0,0,0,0.5);
}
.image__html {
  width: 6rem;
}
.image__css {
  width: 6rem;
}
.image__javascript {
  width: 6rem;
}
.form__contact {
  display: flex;
  flex-direction: column;
}
.footer {
  padding: 2rem;
  grid-area: footer;
  height: 100%;
  text-align: center;
  background: #1d5d76;
  border-radius: 2rem 2rem 0 0;
  box-shadow: .1rem .5rem 1rem #222;
  z-index: 1;
}

@media screen and (min-width: 1400px) {
  .App {
    grid-template-areas:
    ". . header header header . ."
    ". . hero hero hero . ."
    ". . content content content . ."
    ". . footer footer footer . .";
    grid-template-columns: 1fr 1fr 2fr 2fr 2fr 1fr 1fr;
  }
  .hero {
    background-size: 100%;
  }
}
@media screen and (max-width: 410px) {
  .header__navbar li {
    margin: 0 0.5rem 0 0.5rem;
    font-size: 1.5rem;
  }
  .header__navbar li:not(:last-child)::after {
    content: '';
  }
  #about .image__fam {
    padding: 1rem;
    margin: 0 4rem;
  }
}
@media screen and (max-width: 500px) {
  .image__fam {
    border-radius: 5rem 5rem 0 0;
    margin: 0 9rem 1rem;
  }
}
@media screen and (max-width: 630px) {
  #about {
    width: 100%;
  }
  #about p {
    width: 100%;
  }
  #about h1 {
    margin: auto;
    padding: 0;
  }
  .head__container {
  grid-template-areas:
  'logo'
  'navbar'
  'social';
  grid-template-columns: auto;
  grid-template-rows: auto auto auto;
  }
  .App {
    grid-template-columns: 0 auto auto auto 0;
    margin: 0;
  }
  .content {
    grid-template-columns: auto;
  }
  .logo {
    justify-content: center;
  }
  .header__navbar {
    padding-top: 2rem;
    padding-bottom: 1rem;
    justify-content: center;
  }
  .header__navbar li {
    margin: 0 0.5rem 0 0.5rem;
  }
  .header__navbar li:not(:last-child)::after {
    left: 0.5rem;
  }
  .social__links {
    padding-top: 1rem;
    justify-content: center;
  }
  .social path {
    transform: scale(1);
  }
  .social svg {
    width: 3rem;
    height: 3rem;
  }
  #projects h1 {
    margin: auto;
  }
  .project {
    grid-template-areas:
    'title'
    'image'
    'list';
    text-align: left;
    justify-items: center;
  }
  .project h2 {
    margin: 0;
  }
  #contact h1 {
    margin: auto;
  }
}
@media screen and (max-width: 980px) {
  .hero__grid {
    text-align: center;
    grid-template-columns: 1fr;
    grid-template-areas:
  'hero_intro'
  'hero_first'
  'hero_last'
  'hero_and'
  'hero_front';
  margin: 4rem 0 0 0;
  }
  .hero__intro {
    padding-bottom: 1rem;
  }
  .hero__lastname {
    transform: translateY(-2.4rem);
  }
  .hero__and {
    transform: translateY(-2.4rem);
  }
  .hero__webdev {
    transform: translateY(-2.4rem);
  }
}
